import Link from '@/components/Link';
import bemBlock from '@gik/core/utils/bemBlock';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import ArrowCircleRightIcon from '@heroicons/react/outline/ArrowCircleRightIcon';
import type { CSSProperties } from 'react';
import React from 'react';

export interface InkindUsesCardItem {
  title: React.ReactNode;
  description?: React.ReactNode;
  imageUrl?: string;
  linkUrl?: string;
  displayEmpty?: boolean;
}

export interface IInkindUsesCardProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'title'>, InkindUsesCardItem {}

function InkindUsesCardComp({
  className,
  title,
  description,
  imageUrl,
  linkUrl,
  displayEmpty,
}: IInkindUsesCardProps): React.ReactElement {
  const bem = bemBlock('inkind-uses-card');

  let wrapperStyle: CSSProperties = {};
  if (imageUrl) {
    wrapperStyle = imageUrl
      ? {
          backgroundImage: `url('${imageUrl}')`,
        }
      : {};
  }

  const content = (
    <div className={bem('overlay')}>
      <div className={bem('overlay-content')}>
        <span className={bem('title')}>{title}</span>
        {description && <div className={bem('description')}>{description}</div>}
      </div>
      <SvgIcon className={bem('arrow')} Icon={ArrowCircleRightIcon} size="2xl" />
    </div>
  );

  if (linkUrl) {
    return (
      <Link href={linkUrl} className={bem(null, [{ ['display-empty']: displayEmpty }], className)} style={wrapperStyle}>
        {content}
      </Link>
    );
  }

  return (
    <div className={bem(null, [{ ['display-empty']: displayEmpty }], className)} style={wrapperStyle}>
      {content}
    </div>
  );
}

export const InkindUsesCard = withComponentErrorBoundary(InkindUsesCardComp);
