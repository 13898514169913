import WordpressConverter from '@gik/blog/utils/WordpressConverter';
import { Breakpoint, useBreakpoint } from '@gik/core/hooks/hooks/BreakpointHooks';
import type WordpressPost from '@gik/core/models/wordpress/WordpressPost';
import bemBlock from '@gik/core/utils/bemBlock';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import type { ICarouselProps } from '@gik/ui/Carousel';
import { Carousel } from '@gik/ui/Carousel';
import { ArticleTile } from '@gik/ui/gik/ArticleTile';
import React from 'react';

export interface IInkindUsesGridProps {
  skeleton?: boolean;
  posts?: WordpressPost[];
}

export const InkindUsesGridSkeleton = (): React.ReactElement => {
  const bem = bemBlock(`inkind-uses-grid-skeleton`);

  const isMd = useBreakpoint(Breakpoint.MD);
  const isSm = useBreakpoint(Breakpoint.SM);

  const skeletonItems = isMd ? 4 : isSm ? 3 : 1;

  return (
    <div className={`${bem()}`}>
      {Array.from(Array(skeletonItems).keys()).map(index => {
        return <ArticleTile skeleton key={index} showDescription layout={'column'} />;
      })}
    </div>
  );
};

function convertPostArticleLinks(posts: WordpressPost[]) {
  if (!posts) return [];
  return posts.map(post => WordpressConverter.convertPost(post)).filter(WordpressConverter.filterOutPreviewContent);
}

function InkindUsesGridComp({ posts }: IInkindUsesGridProps): React.ReactElement {
  const bem = bemBlock('inkind-uses-grid');

  const articles = React.useMemo(() => {
    return convertPostArticleLinks(posts);
  }, [posts]);

  const isMd = useBreakpoint(Breakpoint.MD);
  const isSm = useBreakpoint(Breakpoint.SM);

  const baseSettings: ICarouselProps = {
    slidesToShow: isMd ? 4 : isSm ? 3.1 : 1.1,
    slidesToScroll: 1,
    arrowButtonSize: 'lg',
    variant: 'primary-default',
    infinite: false,
    dots: true,
    arrows: true,
    hideDisabledArrowButton: true,
  };

  const settings: ICarouselProps = {
    ...baseSettings,
  };

  return (
    <Carousel className={bem()} {...settings}>
      {articles?.map((article, index) => (
        <ArticleTile key={index} article={article} showDescription showAuthor={false} layout={'column'} />
      ))}
    </Carousel>
  );
}

export const InkindUsesGrid = withComponentErrorBoundary(InkindUsesGridComp);
