import { usePostsFeatured } from '@gik/api/posts/postsFeatured';
import { FetchProvider } from '@gik/core/components/DynamicComponent/FetchProvider';
import React from 'react';
import { InkindUsesGrid } from '.';
import type { IInkindUsesGridProps } from './InkindUsesGrid';
import { InkindUsesGridSkeleton } from './InkindUsesGrid';

export function InkindUsesGridWithData(props: IInkindUsesGridProps) {
  const useFetch = () => {
    return usePostsFeatured({ slug: 'benefits-others', perPage: 4 });
  };

  return (
    <FetchProvider fetch={useFetch} loadingComponent={<InkindUsesGridSkeleton />}>
      {({ data }) => <InkindUsesGrid {...props} posts={data} />}
    </FetchProvider>
  );
}
