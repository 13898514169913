import type WordpressAuthor from '@gik/core/models/wordpress/WordpressAuthor';
import type WordpressPost from '@gik/core/models/wordpress/WordpressPost';
import type WordpressPostAcfProductOverride from '@gik/core/models/wordpress/WordpressPostAcfProductOverride';
import type WordpressPostAdvertising from '@gik/core/models/wordpress/WordpressPostAdvertising';
import type WordpressPostAuthor from '@gik/core/models/wordpress/WordpressPostAuthor';
import type WordpressPostProduct from '@gik/core/models/wordpress/WordpressPostProduct';
import type WordpressPrimaryTaxonomyMeta from '@gik/core/models/wordpress/WordpressPrimaryTaxonomyMeta';
import type WordpressRecipe from '@gik/core/models/wordpress/WordpressRecipe';
import type WordpressSituation from '@gik/core/models/wordpress/WordpressSituation';
import type WordpressTag from '@gik/core/models/wordpress/WordpressTag';
import getPreviewMode, { PreviewModeType } from '@gik/core/utils/PreviewMode';
import he from 'he';
import type WordpressArticleType from '../../core/models/wordpress/WordpressArticleType';
import type Article from '../models/Article';
import type { CustomContentLayoutType } from '../models/Article';
import type ArticleAdvertising from '../models/ArticleAdvertising';
import type ArticleType from '../models/ArticleType';
import type Author from '../models/Author';
import type Category from '../models/Category';
import type ProductLink from '../models/ProductLink';
import type Situation from '../models/Situation';

export default class WordpressConverter {
  static filterOutPreviewContent(content: Article) {
    return !content.isPreviewOnly || getPreviewMode(PreviewModeType.Blog);
  }

  static convertAuthor(wordpressAuthor: WordpressAuthor): Author {
    return {
      fullName: wordpressAuthor?.title?.rendered,
      bio: wordpressAuthor?.acf?.bio,
      role: wordpressAuthor?.acf?.byline,
      id: wordpressAuthor?.id?.toString(),
      slug: wordpressAuthor?.slug,
      imageUrl: wordpressAuthor?.acf?.thumb?.sizes?.woocommerce_thumbnail,
      yoastMeta: wordpressAuthor?.yoast_meta,
    };
  }

  static convertSituation(situation: WordpressSituation): Situation {
    return {
      id: situation?.id?.toString(),
      name: situation?.name,
      slug: situation?.slug,
      parentId: situation?.parent?.toString(),
      postCount: situation?.count,
      taxonomy: 'situation',
      yoastMeta: situation?.yoast_meta,
    };
  }

  static convertArticleType(articleType: WordpressArticleType): ArticleType {
    return {
      name: articleType?.name,
      slug: articleType?.slug,
      yoastMeta: articleType?.yoast_meta,
    };
  }

  static convertTag(tag: WordpressTag): Category {
    return {
      id: tag?.id?.toString(),
      name: tag?.name,
      slug: tag?.slug,
      postCount: parseInt(tag?.['count-post-content_tag'] || '0'),
      taxonomy: 'content_tag',
    };
  }

  static convertPost(post: Partial<WordpressPost>): Article {
    if (!post) return null;

    const primaryTaxonomyMeta: WordpressPrimaryTaxonomyMeta = post['primary-taxonomy-meta'];
    const primaryTaxonomySlug = primaryTaxonomyMeta?.taxonomy;
    const taxonomyItemName = primaryTaxonomyMeta?.name;
    const taxonomyItemSlug = primaryTaxonomyMeta?.slug;

    const author: WordpressPostAuthor = post['blog-author'] || {
      ID: -1,
      name: 'Unknown Author',
      slug: null,
    };

    const article: Article = {
      primaryTaxonomySlug,
      taxonomyItemSlug,
      taxonomyItemName,
      title: post?.title?.rendered && he.decode(post.title.rendered),
      excerpt: post?.excerpt?.rendered && he.decode(post.excerpt.rendered),
      authorFullName: author?.name,
      smallImageUrl: post.acf?.thumb?.sizes?.woocommerce_thumbnail,
      largeImageUrl: post.acf?.thumb?.sizes?.['post-thumbnail'],
      //largeImageUrl: post.acf?.thumb?.sizes?.shop_single,
      articleId: post?.id?.toString(),
      articleSlug: post?.slug,
      authorId: author?.ID?.toString(),
      authorSlug: author?.slug,
      date: post?.date,
      content: post?.content?.rendered,
      galleries: [],
      isPreviewOnly: post?.acf?.preview_mode,
      modifiedDate: post?.modified,
      publishedDate: post.date,
      yoastMeta: post?.yoast_meta,
      advertising:
        post?.acf?.ad_override &&
        WordpressConverter.wordpressPostAdvertisingToArticleAdvertising(post?.acf?.advertising),
      customContentLayoutTypes: post?.acf?.custom_content_layouts as CustomContentLayoutType[],
    };
    // TODO: remove eslint-disable-next-line
    // eslint-disable-next-line
    if ((post?.acf as any)?.recipe_title) {
      const acf = post.acf as WordpressRecipe;
      article.recipe = {
        title: acf?.recipe_title,
        description: acf?.recipe_description,
        totalTime: acf?.recipe_total_time,
        prepTime: acf?.recipe_prep_time,
        ingredients: acf?.recipe_ingredients,
        instructions: acf?.recipe_instructions,
        reference: acf?.recipe_reference,
      };
    }

    if (post?.acf?.gallery) {
      article.galleries = post?.acf?.gallery?.map(gallery => ({
        photos: gallery.gallery_images.map(image => ({
          src: image.sizes.shop_single,
          caption: image.caption,
          credit: image.description,
          width: image.width,
          height: image.height,
        })),
        caption: gallery.gallery_caption,
        credit: gallery.gallery_byline_text,
        creditLink: gallery.gallery_byline_link,
      }));
    }
    const productBundleProducts = post['product-bundle-products'] as WordpressPostProduct[];
    if (productBundleProducts) {
      article.productBundle = productBundleProducts.map(product =>
        this._wordpressPostProductToProductLink(product, post.acf?.products)
      );
    }
    const buyingGuideProducts = post['buying-guide-products'] as WordpressPostProduct[];
    if (buyingGuideProducts) {
      article.buyingGuide = buyingGuideProducts.map(product =>
        this._wordpressPostProductToProductLink(product, post.acf?.products)
      );
      // take overrides from original acf field -
      // this is where we can provide a custom description for products on a per-article basis.
      // falls back to using the product description from the buying guide product (the description from the product itself)
      article.buyingGuide = article.buyingGuide.map(productLink => ({
        ...productLink,
        shortDescription:
          post.acf.products.find(p => p.product_id === productLink.id)?.product_short_desc ||
          productLink.shortDescription,
      }));
    }
    return article;
  }

  static wordpressPostAdvertisingToArticleAdvertising(
    wordpressPostAdvertising: WordpressPostAdvertising
  ): ArticleAdvertising {
    return {
      desktopLeaderboard: wordpressPostAdvertising.desktop_leaderboard,
      desktopSkyscraper: wordpressPostAdvertising.desktop_skyscraper,
      mobileMediumRectangle: wordpressPostAdvertising.mobile_medium_rectangle,
      link: wordpressPostAdvertising.link,
    };
  }

  private static _wordpressPostProductToProductLink(
    product: WordpressPostProduct,
    acfProducts?: WordpressPostAcfProductOverride[]
  ): ProductLink {
    const featuredImage = product.images['featured-image'][0];
    const offsiteImageSmall = product.images['offsite_image_small'];
    const offsiteImageLarge = product.images['offsite_image_small'];
    const isTcProduct = product.tc_istcproduct;
    const isPgProduct = product.pg_ispgproduct;

    const acfDescription = acfProducts?.find(
      acfProduct => parseInt(acfProduct.product_id) === product.id
    )?.product_short_desc;

    return {
      title: he.decode(product.name),
      slug: product.slug,
      shortDescription: acfDescription ?? product.description,
      smallImageUrl: offsiteImageSmall || featuredImage?.src?.thumbnail,
      mediumImageUrl: offsiteImageSmall || featuredImage?.src?.api_thumb_square_md,
      largeImageUrl: offsiteImageLarge || featuredImage?.src?.api_thumb_square_lg,
      fullImageUrl: offsiteImageLarge || featuredImage?.src?.full,
      id: product.id.toString(),
      isTcProduct,
      isPgProduct,
      checkoutType: product.checkout_type,
    };
  }
}
