import bemBlock from '@gik/core/utils/bemBlock';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import type { PageSectionProps } from '@gik/ui/gik/PageSection';
import { PageSection } from '@gik/ui/gik/PageSection';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { translationKeys } from './i18n/en';
import { InkindUsesGridWithData } from './InkindUsesGridWithData';

export interface IInkindUsesSectionProps extends Pick<PageSectionProps, 'variant'> {}

function InkindUsesSectionComp({ variant }: IInkindUsesSectionProps): React.ReactElement {
  const bem = bemBlock('inkind-uses-section');
  const { t } = useTranslation();

  return (
    <PageSection id="how-others-are-using-inkind" className={bem()} variant={variant}>
      <h2 className={bem('title')}>{t(translationKeys.title)}</h2>
      <InkindUsesGridWithData />
    </PageSection>
  );
}

export const InkindUsesSection = withComponentErrorBoundary(InkindUsesSectionComp);
